import { AppConfiguration, AppEnvironment } from './interface';

const appEnv: string = process.env.REACT_APP_ENV || "development";

const appModes: Record<string, AppEnvironment> = {
	development: AppEnvironment.DEVELOPMENT,
	staging: AppEnvironment.STAGING,
	production: AppEnvironment.PROD
}

const mode: AppEnvironment = appModes[appEnv]

const appConfigs: Record<string, AppConfiguration> = {
	[AppEnvironment.PROD]: {
		SITE_ENDPOINT: 'https://newhobi.com',
		API_ENDPOINT: 'https://api.newhobi.com',
		SOCKET_ENDPOINT: 'wss://api.newhobi.com',
		API_VERSION: 'api',
		IMAGE_URL: 'https://api.newhobi.com/api/files/uploads/thumbnail/200px/',
	},
	// TODO: ADS: the one below is good for dev
	[AppEnvironment.STAGING]: {
		SITE_ENDPOINT: 'https://dev.newhobi.com',
		API_ENDPOINT: 'https://api.dev.newhobi.com',
		SOCKET_ENDPOINT: 'wss://api.dev.newhobi.com',
		API_VERSION: 'api',
		IMAGE_URL: 'https://api.dev.newhobi.com/api/files/uploads/thumbnail/200px/',
	},
	[AppEnvironment.DEVELOPMENT]: {
		SITE_ENDPOINT: 'http://localhost:3000',
		API_ENDPOINT: 'http://localhost:3005',
		SOCKET_ENDPOINT: 'wss://localhost:3005',
		API_VERSION: 'api',
		IMAGE_URL: 'http://localhost:3005/api/files/uploads/thumbnail/200px/',
	},
};


const data: AppConfiguration = {
	itemsPerPage: 10,
	defaultDateFormat: 'ddd, Do MMMM YYYY',
	defaultActivityDateFormat: 'YYYY-MM-DD HH:mm:s',
	...appConfigs[mode]
}


export const isProd = mode === AppEnvironment.PROD;
export const AppConfig = data;
export const UserTypeEnum = {
	ADMIN: 'admin',
};
export const AcceptedImageFormat = [
	'image/jpg',
	'image/JPG',
	'image/png',
	'image/PNG',
	'image/jpeg',
	'image/JPEG',
	'image/gif',
	'image/GIF',
];

export const AcceptedFileForReport = [
	'image/jpg',
	'image/JPG',
	'image/png',
	'image/PNG',
	'image/jpeg',
	'image/JPEG',
	'.xlsx',
	'.xls',
	'.doc',
	'.docx',
	'.pdf',
	'application/pdf',
	'application/PDF',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/msword',
	'application/vnd.oasis.opendocument.text',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/rtf',
	'application/vnd.oasis.opendocument.spreadsheet',
];

export const AcceptedFileForStripe = [
	'image/jpg',
	'image/JPG',
	'image/png',
	'image/PNG',
	'image/jpeg',
	'image/JPEG',
	'.pdf',
	'application/pdf',
	'application/PDF',
];

export const GoogleMapKey = 'AIzaSyAP2BMKxDZwSruCnd5ui8ZpSYzsz5vxTLw'; // chapter
export const GoogleLocationAutocompleteURL = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapKey}&libraries=places`;
// export const GoogleMapKey = 'AIzaSyCcIwK4VOGn-xw9cRCKF8HaSiR9_ORYtk4'; // atma

export const DEFAULT_LOCATION = { lat: 40.7127753, lng: -74.0059728 }; // New York
export const DEFAULT_BOTTOM_LEFT_BOUND = { lat: 40.6377341631856, lng: -74.06410306816406 };
export const DEFAULT_TOP_RIGHT_BOUND = { lat: 40.7886692970809, lng: -73.94737333183593 };
export const DEFAULT_LOCATION_CITY = '240 Broadway, New York, NY 10007, USA';

export const FULL_CALENDAR_LICENSE =
	mode !== AppEnvironment.DEVELOPMENT
		? process.env.REACT_APP_FULL_CALENDAR_LICENSE
		: 'CC-Attribution-NonCommercial-NoDerivatives';

// export const IMAGE_URL ="http://localhost:4000/api/files/uploads/"
